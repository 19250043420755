//
// Initialize choises.js
//

window.selectors = {};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".choices").forEach(initChoices);
});

function initChoices(el) {
  const classes = [...el.classList];
  const config = {
    allowHTML: true,
    itemSelectText: "",
    placeholder: true,
    placeholderValue: el.dataset.placeholder || "",
    searchEnabled: classes.includes("searchable"),
    searchPlaceholderValue: "Search options ...",
    removeItemButton: classes.includes("clearable"),
    duplicateItemsAllowed: !classes.includes("defaultSelected"),
    shouldSort: false,
    renderSelectedChoices: classes.includes("renderSelected") ? "always" : "auto",
  };

  const templates = {
    "people-narrow": createTemplateFn(getPeopleNarrowHtml),
    people: createTemplateFn(getPeopleHtml),
    role: createTemplateFn(getRoleHtml),
    importance: createTemplateFn(getImportanceHtml),
    location: createTemplateFn(getLocationHtml),
    "location-narrow": createTemplateFn(getLocationNarrowHtml),
    happiness: createTemplateFn(getHappinessHtml),
    area: createTemplateFn(getAreaHtml),
    languages: createTemplateFn(getLanguagesHtml),
  };

  const matchingTemplate = classes.find((cls) => templates[cls]);
  config.callbackOnCreateTemplates = () => {
    const extendedTemplates = {};

    if (matchingTemplate) {
      extendedTemplates.item = extendDefaultTemplate("item", function (defaultItemElement, classNames, data) {
        const customHtml = templates[matchingTemplate].item(classNames, data);
        if (defaultItemElement.firstChild) {
          defaultItemElement.replaceChild(customHtml, defaultItemElement.firstChild);
        } else {
          defaultItemElement.appendChild(customHtml);
        }
        return defaultItemElement;
      });

      extendedTemplates.choice = extendDefaultTemplate("choice", function (defaultItemElement, classNames, data) {
        const customHtml = templates[matchingTemplate].choice(classNames, data);
        if (defaultItemElement.firstChild) {
          defaultItemElement.replaceChild(customHtml, defaultItemElement.firstChild);
        } else {
          defaultItemElement.appendChild(customHtml);
        }

        // Hide the placeholder and override padding on the right (defaults to 100px to show itemSelectText)
        if (classes.includes("clearable") && defaultItemElement.classList.contains("choices__placeholder")) {
          defaultItemElement.classList.add("d-none");
        } else {
          defaultItemElement.classList.add("pe-2");
        }

        return defaultItemElement;
      });
    }

    return extendedTemplates;
  };

  // Initialize and save dropdown into a global variable for possible future needs
  window.selectors[el.id] = new Choices(el, config);

  // Add an opened and has-selection class to the choices wrapper when an option is pre-selected (so we can use the floating-forms CSS and remove the "All" placeholder text). It has to be in the addItem event because the selectedOptions are not available until after the item is added.
  function updateWrapperClasses(el) {
    if (el.selectedOptions.length > 0 && el.selectedOptions[0].innerText !== "") {
      el.parentNode.parentNode.classList.add("opened", "has-selection");
    }
  }
  updateWrapperClasses(el); // Initial check
  el.addEventListener("addItem", () => updateWrapperClasses(el)); // Event listener for when an item is added

  // Scroll the selected option into view when opened
  el.addEventListener("showDropdown", () => {
    // When selected, remove the red border
    el.parentNode.parentNode.classList.remove("required-field-error");

    // Add an opened class to the choices wrapper when opened (so we can use the floating-forms CSS)
    el.parentNode.parentNode.classList.add("opened");

    // Scroll the selected option into view
    const listContainer = el.parentNode.parentNode.querySelector(".choices__list--dropdown .choices__list");

    if (listContainer.querySelectorAll(".choices__item--choice").length > 7) {
      const selectedOption = listContainer.querySelector(".is-selected");

      if (selectedOption) {
        const containerTop = listContainer.getBoundingClientRect().top;
        const containerBottom = listContainer.getBoundingClientRect().bottom;

        const optionTop = selectedOption.getBoundingClientRect().top;
        const optionBottom = selectedOption.getBoundingClientRect().bottom;

        if (optionTop < containerTop) {
          // If the option is above the current viewable area, scroll upward
          listContainer.scrollTop -= containerTop - optionTop;
        } else if (optionBottom > containerBottom) {
          // If the option is below the current viewable area, scroll downward
          listContainer.scrollTop += optionBottom - containerBottom;
        }
      }
    }
  });

  el.addEventListener("hideDropdown", () => {
    // Remove the opened class from the choices wrapper when closed if empty (so we can use the floating-forms CSS)
    if (el.options.length == 0) el.parentNode.parentNode.classList.remove("opened");
  });

  el.addEventListener("change", () => {
    // Remove the opened class from the choices wrapper when closed if empty (so we can use the floating-forms CSS)
    if (el.options.length == 0) el.parentNode.parentNode.classList.remove("opened");

    // Add a class to the wrapper when at least one option is selected (so we can hide the placeholder text)
    el.parentNode.parentNode.classList.toggle("has-selection", el.options.length > 0);
  });

  // Clear the selection if empty class is included
  if (classes.includes("empty")) {
    setTimeout(() => {
      window.selectors[el.id].removeActiveItems();
    }, 0);
  }
}

window.initChoices = initChoices;

//
// Create the HTML for the item and choice
//
function getPeopleNarrowHtml(data) {
  if (["", "Anonymous"].includes(data.label)) return anonymousHtml();
  return `
    <div class="d-inline-flex align-items-center" data-location_ids="${data.customProperties.location_ids}" data-role="${data.customProperties.role || null}">
      <span class="bg-role-${data.customProperties.role || null} me-2 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10"></span>
      <span>${data.label}</span>
    </div>`;
}

function getPeopleHtml(data, isItem) {
  if (["", "Anonymous"].includes(data.label)) return anonymousHtml();
  return `
    <div class="d-flex align-items-center ${isItem ? "pe-4" : ""}" data-location_ids="${data.customProperties.location_ids}" data-role="${data.customProperties.role || null}">
      <span class="text-nowrap">${data.label}</span>
      ${data.customProperties.role == "user" && data.customProperties.dob ? `<span class="text-muted opacity-50 small ms-2 text-nowrap">${data.customProperties.dob || ""}</span>` : ""}
      ${data.customProperties.role !== undefined ? `<span class="badge rounded-pill bg-lighter text-nowrap text-role-${data.customProperties.role} lh-sm ${isItem ? "ms-2 fs-8" : "ms-auto me-n1"}">${data.customProperties.role_label}</span>` : ""}
    </div>`;
}

function getRoleHtml(data) {
  return `
    <div class="${data.value == "" ? "d-none" : "d-inline-flex"}  align-items-center">
      <span class="${data.value == "anonymous" ? "bg-mid" : `bg-role-${data.value || null}`} me-2 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10"></span>
      <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
    </div>`;
}

function getImportanceHtml(data) {
  return `
  <div class="${data.value == "" ? "d-none" : "d-inline-flex"} align-items-center">
    <span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-importance-${data.value}"></span>
    <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
  </div>`;
}

function getLocationHtml(data, isItem) {
  return `
  <div class="${data.value == "" ? "d-none" : "d-flex"} align-items-center" data-type="${data.customProperties.type}">
    <span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-location-${data.value}"></span>
    <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
    ${isItem ? "" : `<span class="ms-auto badge rounded-pill bg-light text-mid lh-sm">${data.customProperties.type_label}</span>`}
  </div>`;
}

function getLocationNarrowHtml(data, isItem) {
  return `
  <div class="${data.value == "" ? "d-none" : "d-flex"} align-items-center" data-type="${data.customProperties.type}">
    <span class="me-2 ms-n1 d-inline-block rounded-circle border border-white flex-shrink-0 s10x10 bg-location-${data.value}"></span>
    <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
  </div>`;
}

function getHappinessHtml(data) {
  return `
  <div class="${data.value == "" ? "d-none" : ""} position-relative text-nowrap happiness-icon">
    <span class="rounded-circle s20x20 d-flex align-items-center justify-content-center ${data.customProperties.class} position-absolute top-0 start-0">
      <svg class="bi" width="20" height="20">
        <use xlink:href="#${data.customProperties.icon}"></use>
      </svg>
      <span class="visually-hidden">${data.label}</span>
    </span>
    <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
  </div>`;
}

function getAreaHtml(data) {
  return `
  <div class="${data.value == "" ? "d-none" : ""} position-relative text-nowrap area-icon">
    <span class="rounded-circle s20x20 d-flex align-items-center justify-content-center bg-brand text-white position-absolute top-0 start-0">
      ${data.customProperties.icon.replace("viewBox", "width=20 height=20 viewBox")}
    </span>
    <span class="text-nowrap text-truncate lh-sm">${data.label}</span>
  </div>`;
}

// New function for survey languages template
function getLanguagesHtml(data, isItem) {
  return `
  <div class="d-inline-flex align-items-center">
    <img src="/images/languages/${data.value}.png" alt="${data.value}" class="me-2 s24x24 rounded-circle" />
    <span class="text-nowrap">${data.label}</span>
  </div>`;
}

// Helper function
function anonymousHtml() {
  return `
    <div class="d-inline-flex align-items-center text-secondary">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill opacity-75 me-2" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
      </svg>
      <span class="opacity-75">Anonymous</span>
    </div>`;
}

//
// Render functions
//

// Create the template functions for item and choice
function createTemplateFn(getHtmlFn) {
  return {
    item: (nil, data) => createDOMElementFromHTML(getHtmlFn(data, true)),
    choice: (nil, data) => createDOMElementFromHTML(getHtmlFn(data, false)),
  };
}

function createDOMElementFromHTML(htmlStr) {
  const template = document.createElement("template");
  template.innerHTML = htmlStr.trim();
  return template.content.firstChild;
}

function extendDefaultTemplate(templateName, fn) {
  const defaultTemplateFn = Choices.defaults.templates[templateName];
  return (...args) => {
    return fn.call(this, defaultTemplateFn.call(this, ...args), ...args);
  };
}
